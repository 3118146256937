import styled from 'styled-components';
import variables, { mq, isTouchMedia, fs } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;

  ${mq('m')} {
    flex-direction: column;
    padding-bottom: 120px;
  }

  .slider {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    /* min-height: 490px; */
    width: calc(60% + 64px);
    overflow: hidden;
    padding-right: 64px;

    ${mq('m')} {
      width: 100%;
      padding-left: 64px;
    }

    ${mq('s')} {
      padding-left: 20px;
      padding-right: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 64px;
      height: 100%;
      // background: linear-gradient(90deg, transparent 0%, ${variables.colors.backgroundDark} 100%);
      z-index: 5;

      ${mq('m')} {
        display: none;
      }
    }

    .slider-container {
      width: 363px;

      ${mq('m')} {
        width: 100%;
      }
    }

    .swiper-wrapper {
      align-items: flex-end;

      ${mq('m')} {
        align-items: flex-start;
      }
    }

    .swiper-container {
      overflow: visible;
    }

  }

  .slider-buttons {
    position: absolute;
    bottom: 145px;
    left: calc( 60% + 64px);
    display: flex;
    flex-direction: column;

    ${mq('m')} {
      left: 64px;
      bottom: 70px;
      right: 64px;
      flex-direction: row-reverse;
      justify-content: center;
    }

    button {
      display: block;
      width: 48px;
      height: 48px;
      border: 0;
      border-radius: 50%;
      background: ${variables.colors.red} none;
      outline: none;
      cursor: pointer;

      + button {
        margin-top: 20px;

        ${mq('m')} {
          margin-top: 0;
          margin-right: 20px;
        }
      }

      svg path {
        fill: ${variables.colors.white};
      }
      ${isTouchMedia()} {
        &:hover {
          background: ${variables.colors.blue} none;

          svg path {
            fill: ${variables.colors.white};
          }
        }
      }
    

      &:disabled {
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
      }

      
    }
  }
`;

export const Content = styled.div`
  padding: 0 0 0 4.22vw;

  ${mq('m')} {
    width: 100%;
    padding-left: 64px;
    // padding-right: 40%;
  }

  ${mq('s')} {
    width: 100%;
    padding-left: 20px;
    //padding-right: 40%;
  }

  .text {
    width: 22.15vw;

    ${mq('m')} {
      width: auto;
    }
  }

  .title {
    margin-bottom: 2.2vw;
    font-family: 'Bossa', sans-serif;
    font-weight: 500;
    line-height: 1.3;
    color: ${variables.colors.white};

    ${fs(36)}
  }
`;

export const TestimonailCard = styled.div`
  > div {
    margin-top: 100px;
    margin-bottom: 100px;
    padding: 35px;
    background: ${variables.colors.backgroundDark};
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    box-shadow: 0px 10px 100px rgba(7, 13, 28, 0.25);

    ${mq('m')} {
      margin-top: 30px;
      margin-bottom: 30px;
      box-shadow: 0px 10px 30px rgba(7, 13, 28, 0.25);
    }
  }

  &.swiper-slide-prev > div {
    box-shadow: none;
  }

  .avatar {
    display: block;
    width: 72px;
    height: 72px;
    margin: 0 auto 25px;
    border-radius: 50%;
    overflow: hidden;
  }

  .name {
    margin-bottom: 5px;
    font-family: 'Bossa', sans-serif;
    color: ${variables.colors.white};

    ${fs(18)}
  }

  .link {
    display: inline-block;
    margin-bottom: 30px;
    color: ${variables.colors.red};
    font-family: 'Bossa', sans-serif;
    text-decoration: none;

    ${fs(16)}

    ${isTouchMedia()} {
      &:hover {
        color: ${variables.colors.blue};
      }
    }
  }

  .text {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    line-height: 1.6;
    color: ${variables.colors.white};

    ${fs(16)}

    a {
      color: ${variables.colors.red};
      text-decoration: none;

      ${isTouchMedia()} {
        &:hover {
          color: ${variables.colors.blue};
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-between;
    margin: 40px -35px -35px;
    background: ${variables.colors.backgroundSuperDark};

    .company {
      display: flex;
      align-items: center;
      width: 100%;
      max-width: calc(100% - 101px);
      padding: 10px 20px;
      text-decoration: none;
      color: ${variables.colors.white};
      text-align: left;
      ${fs(14)}

      .name {
        display: flex;
        justify-content: center;
        flex-direction: column;
        max-width: calc(100% - 56px);
        text-align: left;
        font-family: Montserrat;
        font-weight: 500;
        line-height: 1.5;
        ${fs(14)}

        .title {
          white-space: nowrap;
          max-width: 100%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      } 

      &:hover {
        .avatar {
          
          transform: scale(1.1);
        }
        .name .title,
        .name .label {
          color: ${variables.colors.blue};
        }
      }
    }

    .avatar {
      width: 36px;
      height: 36px;
      margin: 0 20px 0 0;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0;
      }
    }

    .tweet {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      border-left: 1px solid ${variables.colors.backgroundDark};

      svg {
        width: 20px;
        height: 20px;

        path {
          fill: ${variables.colors.blue};
        }
      }
    }
  }
`;