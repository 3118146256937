import React, { useEffect, useState, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "../components/image"

import { Button } from "../components/common/button"
import { Section } from "../components/common/section"
import VideoOverlayer from "../components/video-overlayer"
import Hero from "../components/hero"
import Partners from "../components/partners"
import Feature from "../components/feature"
import Ready from "../components/ready"
import Testimonials from "../components/testimonials"

import illuReady from "../images/illu-ready.svg"
import illuTestimonials from "../images/illu-testimonials.svg"

import featuresIllu1 from "../images/features-illu1.svg"
import featuresIllu2 from "../images/features-illu2.svg"
import featuresIllu3 from "../images/features-illu3.svg"
import featuresIllu4 from "../images/features-illu4.svg"
import featuresIllu5 from "../images/features-illu5.svg"

import playCursor from "../images/play.svg"

// import featureVisualLiveCollab from '../images/features/visual-live-collab.svg';
// import featureVisualDesign from '../images/features/visual-design.svg';
// import featureVisualContent from '../images/features/visual-content.svg';
// import featureVisualPitch from '../images/features/visual-pitch.svg';

// import Lottie from 'react-lottie';

import bodymovin from "lottie-web"

// import animationHero from '/animations/homepage-hero.json';

// import animationOrganize from '../animations/homepage-organize.json';
// import animationContent from '../animations/homepage-content.json';
// import animationDesign from '../animations/homepage-design.json';
// import animationPitch from '../animations/homepage-pitch.json';

import heroMobile from "../images/hero-mobile.svg"
import partnerMobile from "../images/partner-mobile.svg"

import heroVideo from "../videos/Homecast_Final_compressed.mp4"
import heroVideoFull from "../videos/2020-10-ludus-screencast_EDIT04B.mp4"

import heroVideoPoster from "../videos/Homecast_Final_compressed-poster.png"
import heroVideoFullPoster from "../videos/2020-10-ludus-screencast_EDIT04B-poster.png"

import sal from "sal.js"

// let animationHeroInst = null

const IndexPage = () => {
  const [currentBreakPoint, setCurrentBreakpointChildren] = useState({
    type: null,
    bp: null,
  })

  let lottieHeroAnimation = useRef()

  const [loaded, setLoaded] = useState(false)
  const [videoFullscreen, setVideoFullscreen] = useState(false)

  const videoCursor = useRef(null)
  const videoHero = useRef(null)
  const videoFull = useRef(null)
  const videoContainer = useRef(null)
  const visual = useRef(null)

  const updateBreakpoint = currentBreakPoint => {
    setCurrentBreakpointChildren(currentBreakPoint)
    if (loaded) {
      if (currentBreakPoint.type === "desktop") {
        setTimeout(() => {}, 150)
      } else if (currentBreakPoint.type === "mobile") {
        setTimeout(() => {}, 150)
      }
    }
  }

  useEffect(() => {
    setLoaded(true)
    sal()
    if (lottieHeroAnimation && lottieHeroAnimation.current) {
      heroLottieRun()
    }
  }, [])

  const heroLottieRun = () => {
    bodymovin.loadAnimation({
      useSubFrames: false,
      wrapper: lottieHeroAnimation.current,
      renderer: "svg",
      loop: false,
      path: "/animations/homepage-hero.json",
    })
  }

  return (
    <Layout
      currentPage="homepage"
      headerDark={true}
      updateBreakpoint={updateBreakpoint}
    >
      <SEO title="Home" />

      <Section dark={true}>
        {currentBreakPoint.bp === "xs" ||
        currentBreakPoint.bp === "s" ||
        currentBreakPoint.bp === "m" ? (
          <img
            src={heroMobile}
            alt=""
            style={{
              position: "absolute",
              zIndex: "0",
              top: "0",
              right: "0",
              display: "block",
              width: "80%",
              height: "47%",
            }}
          />
        ) : null}

        <div
          style={{
            position: "absolute",
            zIndex: "0",
            width:
              currentBreakPoint.bp === "xs" ||
              currentBreakPoint.bp === "s" ||
              currentBreakPoint.bp === "m"
                ? "70%"
                : "59%",
            bottom: "17%",
            left: "0",
          }}
        >
          <div>
            {currentBreakPoint.bp === "xs" ||
            currentBreakPoint.bp === "s" ||
            currentBreakPoint.bp === "m" ? (
              <img
                src={partnerMobile}
                alt=""
                style={{
                  display: "block",
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <div
                ref={lottieHeroAnimation}
                style={{ width: "100%", height: "100%" }}
              />
            )}
          </div>
        </div>

        <Hero dark={true} alignOn="left">
          <div
            ref={visual}
            className="visual"
            // data-sal="slide-left"
            // data-sal-duration="1000"
            onMouseEnter={e => {
              const value = e.currentTarget.getBoundingClientRect()
              videoCursor.current.style.top = e.clientY + "px"
              videoCursor.current.style.left = e.clientX - value.x + "px"
              videoCursor.current.style.opacity = 1
            }}
            onMouseLeave={e => {
              const value = e.currentTarget.getBoundingClientRect()
              videoCursor.current.style.top = e.clientY - value.top + "px"
              videoCursor.current.style.left = e.clientX - value.x + "px"
              videoCursor.current.style.opacity = 0
            }}
            onMouseMove={e => {
              const value = e.currentTarget.getBoundingClientRect()
              videoCursor.current.style.top = e.clientY - value.top + "px"
              videoCursor.current.style.left = e.clientX - value.x + "px"
            }}
            onClick={e => {
              setVideoFullscreen(true)
            }}
          >
            <div
              className="video-wrapper before-loaded"
              data-sal="slide-left"
              data-sal-duration="1000"
              ref={videoContainer}
              style={{ opacity: "0" }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <div className="top-image">
                  <Image filename="hero-video-browserbar.png" />
                </div>
                <video
                  ref={videoHero}
                  muted
                  autoPlay
                  loop
                  playsInline
                  poster={heroVideoPoster}
                  onLoadedData={e => {
                    videoContainer.current.style.opacity = ".999"
                    videoContainer.current.classList.remove("before-loaded")
                  }}
                >
                  <source src={heroVideo} type="video/mp4" />
                </video>
              </div>
            </div>

            <div className="video-cursor" ref={videoCursor}>
              <img
                src={playCursor}
                alt="click to play full video"
                width="150px"
                height="150px"
              />
            </div>
          </div>
          <div className="content">
            <h1 className="title" data-sal="slide-up" data-sal-duration="500">
              Collaborative presentations
              <br />
              for creative teams
            </h1>
            <div
              className="text"
              data-sal="slide-up"
              data-sal-duration="500"
              data-sal-delay="500"
            >
              <p>
                The presentation tool that combines creativity with
                simplicity... and a bit of magic.
              </p>
              <div
                className="button-wrapper-note"
                data-sal="slide-up"
                data-sal-duration="500"
                data-sal-delay="1000"
              >
                <Button dark={true} href="https://app.ludus.one/signup">
                  Try Ludus for free
                </Button>
                <p className="button-note">
                  <svg
                    width="19"
                    height="16"
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.22476 15.0056C8.22476 15.0056 9.46657 14.8977 9.81618 14.0761C10.1777 13.2259 9.49354 12.8784 9.49354 12.8784C9.49354 12.8784 10.4864 12.909 10.8536 11.8715C11.2001 10.8938 10.3567 10.4451 10.3567 10.4451C10.3567 10.4451 11.3272 10.2858 11.6104 9.25617C11.8485 8.38939 11.0051 7.74359 11.0051 7.74359H17.3376C17.835 7.74359 18.6733 7.46244 18.6738 6.42449C18.6748 5.38706 17.8132 5.15001 17.3376 5.15001H6.70389C6.18517 5.15001 6.18517 4.63129 6.70389 4.63129H8.08679C10.0538 4.63129 11.2733 3.57415 11.6099 3.2043C11.9471 2.83446 12.1177 2.3396 11.9906 1.74152C11.7754 0.726916 10.9947 0.948408 10.3323 1.23578C8.7357 1.9293 6.46061 2.40496 5.38479 2.54968C2.34615 2.95999 0.00311347 5.56291 1.17323e-06 8.71411C-0.00207369 12.1511 2.74816 15.003 6.18517 15.0056H8.22476Z"
                      fill="#FFDC5D"
                    />
                  </svg>
                  no credit card required
                </p>
              </div>
            </div>
          </div>
        </Hero>

        <Partners dark={true} />
      </Section>
      <Section>
        <Feature
          reverse={false}
          animation="/animations/organize_together.json"
          animationRepeat={true}
          headline="Organize together"
          title="Let everyone focus on what they are good at."
          text="Work with your team together and realtime on any of your presentations. Organize your workflow with shared channels. Define a role for everyone and let them work in confidence."
          url="/organize-together"
          link="Learn more"
          minispace={true}
        >
          {currentBreakPoint.bp !== "xs" &&
          currentBreakPoint.bp !== "s" &&
          currentBreakPoint.bp !== "m" ? (
            <div
              style={{
                position: "absolute",
                zIndex: "0",
                width: "13%",
                top: "97%",
                left: "3%",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    background: `transparent url(${featuresIllu1}) no-repeat 100% 50%`,
                    backgroundSize: "contain",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </Feature>

        <Feature
          reverse={true}
          animation="/animations/create_without_limits.json"
          animationRepeat={true}
          headline="Create without limits"
          title="Finally a presentation tool designers will love."
          text="You are in a familiar designer environment. You can use your favorite fonts, crop your pictures, GIFs or videos, and apply blending modes wherever you like. You can even build a library of re-usable smart blocks."
          url="/create-without-limits"
          link="Learn more"
          minispace={true}
        >
          {currentBreakPoint.bp !== "xs" &&
          currentBreakPoint.bp !== "s" &&
          currentBreakPoint.bp !== "m" ? (
            <div
              style={{
                position: "absolute",
                zIndex: "0",
                width: "13%",
                bottom: "10%",
                left: "42%",
                transform: "translate(0, 20%)",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    transform: "translate(0, 20%)",
                    background: `transparent url(${featuresIllu2}) no-repeat 100% 50%`,
                    backgroundSize: "contain",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                zIndex: "0",
                width: "45%",
                bottom: "55%",
                left: "62%",
                transform: "translate(0, 20%)",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    transform: "translate(0, 20%)",
                    background: `transparent url(${featuresIllu2}) no-repeat 100% 50%`,
                    backgroundSize: "contain",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Feature>

        <Feature
          reverse={false}
          animation="/animations/connect_the_blocks.json"
          animationRepeat={true}
          headline="Connect the blocks"
          title="Add any kind of content to your slides to tell the perfect story."
          text="In Ludus each piece of content is a block that can come from anywhere (Unsplash, GIPHY, Dropbox, Google Drive, etc). It can even come your own team, we then called them Smart Blocks (it can be anything: a header, a chart, an image grid, a button, etc). Building your story will just be about assembling the right blocks together."
          url="/connect-the-blocks"
          link="Learn more"
          minispace={true}
        >
          {currentBreakPoint.bp !== "xs" &&
          currentBreakPoint.bp !== "s" &&
          currentBreakPoint.bp !== "m" ? (
            <div
              style={{
                position: "absolute",
                zIndex: "0",
                width: "13%",
                top: "-10%",
                left: "0",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    background: `transparent url(${featuresIllu3}) no-repeat 0 0`,
                    backgroundSize: "contain",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}

          {currentBreakPoint.bp !== "xs" &&
          currentBreakPoint.bp !== "s" &&
          currentBreakPoint.bp !== "m" ? (
            <div
              style={{
                position: "absolute",
                zIndex: "0",
                width: "13%",
                top: "35%",
                right: "28%",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    background: `transparent url(${featuresIllu4}) no-repeat 100% 50%`,
                    backgroundSize: "contain",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                zIndex: "0",
                width: "50%",
                top: "22%",
                right: "2%",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    background: `transparent url(${featuresIllu4}) no-repeat 100% 50%`,
                    backgroundSize: "contain",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Feature>

        <Feature
          reverse={true}
          animation="/animations/pitch_in_peace.json"
          animationRepeat={true}
          headline="Pitch in peace"
          title="Like Steve Jobs. Or in your pajamas hidden behind a computer."
          text="It's up to you: Present live or send your presentation with a customized URL. For more emotions and context, record and embed yourself in your slides thanks to Ludus Vox."
          url="/pitch-in-peace"
          link="Learn more"
          minispace={true}
        >
          {currentBreakPoint.bp !== "xs" &&
          currentBreakPoint.bp !== "s" &&
          currentBreakPoint.bp !== "m" ? (
            <div
              style={{
                position: "absolute",
                zIndex: "0",
                width: "13%",
                top: "15%",
                left: "17%",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    background: `transparent url(${featuresIllu5}) no-repeat 100% 50%`,
                    backgroundSize: "contain",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "absolute",
                zIndex: "0",
                width: "48%",
                top: "23%",
                right: "-3%",
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    background: `transparent url(${featuresIllu5}) no-repeat 100% 50%`,
                    backgroundSize: "contain",
                  }}
                >
                  <div
                    style={{
                      paddingBottom: "100%",
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </Feature>
      </Section>

      <Section dark={true}>
        {currentBreakPoint.bp !== "xs" &&
        currentBreakPoint.bp !== "s" &&
        currentBreakPoint.bp !== "m" ? (
          <div
            style={{
              display:
                currentBreakPoint.bp === "m" ||
                currentBreakPoint.bp === "s" ||
                currentBreakPoint.bp === "xs"
                  ? "none"
                  : "block",
              position: "absolute",
              zIndex: "0",
              width: "45%",
              paddingBottom: "45%",
              top: "-17%",
              right: "0",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background: `transparent url(${illuReady}) no-repeat 100% 50%`,
                  backgroundSize: "contain",
                }}
              />
            </div>
          </div>
        ) : null}
        {currentBreakPoint.bp !== "xs" &&
        currentBreakPoint.bp !== "s" &&
        currentBreakPoint.bp !== "m" ? (
          <div
            style={{
              position: "absolute",
              zIndex: "0",
              width: "26%",
              height: "51%",
              bottom: "10.5%",
              left: "0",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  background: `transparent url(${illuTestimonials}) no-repeat 0 50%`,
                  backgroundSize: "contain",
                }}
              />
            </div>
          </div>
        ) : null}
        <Ready dark={true} />
        <Testimonials
          style={{ minHeight: "650px" }}
          mobile={
            currentBreakPoint.bp === "xs" ||
            currentBreakPoint.bp === "s" ||
            currentBreakPoint.bp === "m"
          }
        />
      </Section>

      <VideoOverlayer
        opened={videoFullscreen}
        closeOverlayerFn={setVideoFullscreen}
        handleOpen={() => {
          if (videoHero.current) {
            videoHero.current.pause()
            videoFull.current.play()
            videoFull.current.currentTime = 0
          }
        }}
        handleClose={() => {
          if (videoHero.current) {
            videoHero.current.play()
            videoFull.current.pause()
            videoFull.current.currentTime = 0
          }
        }}
      >
        <video
          ref={videoFull}
          playsInline
          controls
          poster={heroVideoFullPoster}
        >
          <source src={heroVideoFull} type="video/mp4" />
        </video>
      </VideoOverlayer>
    </Layout>
  )
}

export default IndexPage
