import React, { useRef, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

import { Container, Content, TestimonailCard } from './styled.js';

// import yakudoo from '../../images/testimonials-avatars/yakudoo.jpg';
// import randfish from '../../images/testimonials-avatars/randfish.jpg';
// import sortlist from '../../images/testimonials-avatars/sortlist.jpg';
// import sparktoro from '../../images/testimonials-avatars/sparktoro.jpg';
// import sunrise from '../../images/testimonials-avatars/sunrise.png';
// import upskydown from '../../images/testimonials-avatars/Upskydown.jpg';
// import perlerar from '../../images/testimonials-avatars/perlerar.jpg';
// import nike from '../../images/testimonials-avatars/nike.jpg';
// import nuxt_js from '../../images/testimonials-avatars/nuxt_js.jpg';
// import igaenssley from '../../images/testimonials-avatars/igaenssley.jpg';
// import intercom from '../../images/testimonials-avatars/intercom.png';
// import jeremylv from '../../images/testimonials-avatars/jeremylv.jpg';
// import lionldn from '../../images/testimonials-avatars/LionLDN.jpg';
// import melmilloway from '../../images/testimonials-avatars/MelMilloway.png';
// import nifinet from '../../images/testimonials-avatars/nifinet.jpg';
// import epicwebagency from '../../images/testimonials-avatars/epicwebagency.jpg';
// import bryanmccarty from '../../images/testimonials-avatars/bryanmccarty.jpg';
// import carlmartin from '../../images/testimonials-avatars/carlmartin.jpg';
// import destraynor from '../../images/testimonials-avatars/destraynor.jpg';
// import dogstudio from '../../images/testimonials-avatars/dogstudio.jpg';
// import bettercloud from '../../images/testimonials-avatars/bettercloud.jpg';
// import amazon from '../../images/testimonials-avatars/amazon.jpg';
// import atinux from '../../images/testimonials-avatars/Atinux.jpg';
// import aha_io from '../../images/testimonials-avatars/aha_io.png';

import Image from "../image"

const yakudoo = 'testimonials-avatars/yakudoo.jpg';
const randfish = 'testimonials-avatars/randfish.jpg';
const sortlist = 'testimonials-avatars/sortlist.jpg';
const sparktoro = 'testimonials-avatars/sparktoro.jpg';
const sunrise = 'testimonials-avatars/sunrise.png';
const upskydown = 'testimonials-avatars/Upskydown.jpg';
const perlerar = 'testimonials-avatars/perlerar.jpg';
const nike = 'testimonials-avatars/nike.jpg';
const nuxt_js = 'testimonials-avatars/nuxt_js.jpg';
const igaenssley = 'testimonials-avatars/igaenssley.jpg';
const intercom = 'testimonials-avatars/intercom.png';
const jeremylv = 'testimonials-avatars/jeremylv.jpg';
const lionldn = 'testimonials-avatars/LionLDN.jpg';
const melmilloway = 'testimonials-avatars/MelMilloway.png';
const nifinet = 'testimonials-avatars/nifinet.jpg';
const epicwebagency = 'testimonials-avatars/epicwebagency.jpg';
const bryanmccarty = 'testimonials-avatars/bryanmccarty.jpg';
const carlmartin = 'testimonials-avatars/carlmartin.jpg';
const destraynor = 'testimonials-avatars/destraynor.jpg';
const dogstudio = 'testimonials-avatars/dogstudio.jpg';
const bettercloud = 'testimonials-avatars/bettercloud.jpg';
const amazon = 'testimonials-avatars/amazon.jpg';
const atinux = 'testimonials-avatars/Atinux.jpg';
const aha_io = 'testimonials-avatars/aha_io.png';

const testimonialsData = [{
  avatar: nifinet,
  name: 'Nicolas Finet',
  author: {
    url: 'https://twitter.com/nifinet',
    label: '@nifinet'
  },
  tweet: {
    url: 'https://twitter.com/nifinet/status/930454191759388672'
  },
  text: `
  Embed your Ludus presentations in your Medium posts by
  <a href="https://twitter.com/vinchbat" target="_blank" rel="noopener noreferrer">@vinchbat</a>
  <a href="https://blog.ludus.one/embed-your-ludus-presentations-in-your-medium-posts-629aabfc97e6"
    target="_blank" rel="noopener noreferrer">https://blog.ludus.one/embed-your-lud …</a><br /><br />Great product
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a>,
  our designers love using it 😍
  `,
  footer: {
  avatar: sortlist,
  name: 'Sortlist',
  title: 'Co-Founder',
  link: {
  url: 'https://twitter.com/sortlist',
  label: '@sortlist'
    }
  }
},
{
  avatar: destraynor,
  name: 'Des Traynor',
  author: {
  url: 'https://twitter.com/destraynor',
  label: '@destraynor'
  },
  tweet: {
  url: 'https://twitter.com/destraynor/status/930582049454518272'
  },
  text: `
  The Ludus product by
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a>
  looks really interesting, first time I've had my head turned from
  Keynote in a long time.
  `,
  footer: {
  avatar: intercom,
  name: 'Intercom',
  title: 'Co-Founder',
  link: {
  url: 'https://twitter.com/intercom',
  label: '@intercom'
    }
  }
},
{
  avatar: bryanmccarty,
  name: 'Bryan McCarty',
  author: {
  url: 'https://twitter.com/bryanmccarty',
  label: '@bryanmccarty'
  },
  tweet: {
  url: 'https://twitter.com/bryanmccarty/status/930808218355470336'
  },
  text: `
  Okay
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a>
  your presentation tool looks pretty amazing!
  `,
  footer: {
  avatar: aha_io,
  name: 'Aha!',
  title: 'Product Marketing',
  link: {
  url: 'https://twitter.com/aha_io',
  label: '@aha_io'
    }
  }
},
{
  avatar: perlerar,
  name: 'Regy Perlera',
  author: {
  url: 'https://twitter.com/perlerar',
  label: '@perlerar'
  },
  tweet: {
  url: 'https://twitter.com/perlerar/status/862320327849914368'
  },
  text: `
  Probably one of the best things I've seen in a while. Can't wait to
  try it out
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a>
  `,
  footer: {
  avatar: nike,
  name: 'Nike',
  title: 'Design',
  link: {
  url: 'https://twitter.com/nike',
  label: '@nike'
    }
  }
},
{
  avatar: randfish,
  name: 'Rand Fishkin',
  author: {
  url: 'https://twitter.com/randfish',
  label: '@randfish'
  },
  tweet: {
  url: 'https://twitter.com/randfish/status/863809501350084608'
  },
  text: `
  This tool for making designer-quality web presentations looks pretty
  sweet. Just signed up.
  `,
  footer: {
  avatar: sparktoro,
  name: 'SparkToro',
  title: 'Founder',
  link: {
  url: 'https://twitter.com/sparktoro',
  label:'sparktoro'
    }
  }
},
{
  avatar: yakudoo,
  name: 'Karim Maaloul',
  author: {
  url: 'https://twitter.com/yakudoo',
  label: '@yakudoo'
  },
  tweet: {
  url: 'https://twitter.com/yakudoo/status/1009205745550753792'
  },
  text: `
  You can find my presentation for the
  <a href="https://twitter.com/hashtag/CodePenBelgium" target="_blank" rel="noopener noreferrer">#CodePenBelgium</a>
  here:
  <a href="https://app.ludus.one/a4465cd5-ee82-4534-9755-5e658a7cb198" target="_blank" rel="noopener noreferrer">https://app.ludus.one/a4465cd5-ee82
    …</a>
  . Made with
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a> and
  enjoyed every bit of it. Great presentation tool and the
  <a href="https://twitter.com/CodePen" target="_blank" rel="noopener noreferrer">@CodePen</a>
  integration works perfectly.
  `,
  footer: {
  avatar: epicwebagency,
  name: 'EPIC Agency',
  title: 'Partner &amp; Creative Director',
  link: {
  url: 'https://twitter.com/epicwebagency',
  label: '@epicwebagency'
    }
  }
},
{
  avatar: melmilloway,
  name: 'Mel Milloway',
  author: {
  url: 'https://twitter.com/MelMilloway',
  label: '@MelMilloway'
  },
  tweet: {
  url: 'https://twitter.com/MelMilloway/status/996053557315977216'
  },
  text: `
  My favorite presentation tool
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a>
  which "I think" also happens to be a Belgium company and I am speaking
  in Belgium so it's super cool
  `,
  footer: {
  avatar: amazon,
  name: 'Amazon',
  title: 'Senior Learning Experience Designer',
  link: {
  url: 'https://twitter.com/amazon',
  label: '@amazon'
    }
  }
},
{
  avatar: atinux,
  name: 'Sébastien Chopin',
  author: {
  url: 'https://twitter.com/Atinux',
  label: '@Atinux'
  },
  tweet: {
  url: 'https://twitter.com/Atinux/status/981541193938096128'
  },
  text: `
  I think I found my next presentation tool for my slides, great job
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a> 🙌
  `,
  footer: {
  avatar: nuxt_js,
  name: 'Nuxt.js',
  title: 'Author',
  link: {
  url: 'https://twitter.com/nuxt_js',
  label: '@nuxt_js'
    }
  }
},
{
  avatar: igaenssley,
  name: 'Ian Gaenssley',
  author: {
  url: 'https://twitter.com/igaenssley',
  label: '@igaenssley'
  },
  tweet: {
  url: 'https://twitter.com/igaenssley/status/966791488922357760'
  },
  text: `
  Making slides has all the fun of getting hit with a sack of hot
  nickels. That is until I started playing with
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a>.
  This is quite a joy to use, and leaves less marks.
  `,
  footer: {
  avatar: bettercloud,
  name: 'BetterCloud',
  title: 'Senior UX Designer',
  link: {
  url: 'https://twitter.com/bettercloud',
  label: '@bettercloud'
    }
  }
},
{
  avatar: jeremylv,
  name: 'Jeremy Le Van',
  author: {
  url: 'https://twitter.com/jeremylv',
  label: '@jeremylv'
  },
  tweet: {
  url: 'https://twitter.com/jeremylv/status/963344731878166529'
  },
  text: `
  Great to see
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a>
  being featured by
  <a href="https://twitter.com/InVisionApp" target="_blank" rel="noopener noreferrer">@InVisionApp</a>. Love their product!
  `,
  footer: {
  avatar: sunrise,
  name: 'Sunrise',
  title: 'Co-Founder',
  link: {
  url: 'https://twitter.com/sunrise',
  label: '@sunrise'
    }
  }
},
{
  avatar: upskydown,
  name: 'Henry Daubrez',
  author: {
  url: 'https://twitter.com/Upskydown',
  label: '@Upskydown'
  },
  tweet: {
  url: 'https://twitter.com/Upskydown/status/1001095055623507968'
  },
  text: `
  Wow!
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a>
  just released this groundbreaking tool for their already powerful
  online presentation tool. You can now pre-record yourself doing the
  presentation you're sharing, which means huge e-learning / client
  presentation possibilities.
  <a href="https://blog.ludus.one/meet-ludus-vox-80a8fbf46f9a" target="_blank" rel="noopener noreferrer">https://blog.ludus.one/meet-ludus-vox …</a>
  `,
  footer: {
  avatar: dogstudio,
  name: 'Dogstudio',
  title: 'Executive Creative Director',
  link: {
  url: 'https://twitter.com/dogstudio',
  label: '@dogstudio'
    }
  }
},
{
  avatar: carlmartin,
  name: 'Carl Martin',
  author: {
    url: 'https://twitter.com/carlmartin',
    label: '@carlmartin'
  },
  tweet: {
  url: 'https://twitter.com/carlmartin/status/1001530863069401090'
  },
  text: `
  The team at
  <a href="https://twitter.com/ludushq" target="_blank" rel="noopener noreferrer">@ludushq</a> are
  MACHINES.
  `,
  footer: {
    avatar: lionldn,
    name: 'Lion London',
    title: 'Dean',
    link: {
      url: 'https://twitter.com/LionLDN',
      label: '@LionLDN'
    }
  }
}];

const Testimonials = (props) => {

  const ref = useRef(null);

  const params = {
    slidesPerView: 1,
    spaceBetween: 64,
    rtl: true,
    loop: true,
    loopedSlides: 5,
    autoHeight: props.mobile,
    rebuildOnUpdate: props.mobile
  }

  const goNext = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slidePrev();
    }
  };

  const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  let randomData = testimonialsData;

  useEffect(() => {
    randomData = shuffle(testimonialsData);
  }, []);

  
  
  return (
    <Container>
      <Content>
        <div className="text" data-sal="slide-left" data-sal-duration="500">
          <h2 className="title">Yup, that’s what they said.</h2>
        </div>
      </Content>
      <div
        className="slider"
        data-sal="slide-right" data-sal-duration="500" data-sal-delay="100"
      >
        <div className="slider-container">
          <Swiper ref={ref} {...params}>
            {randomData.map((item, index) => (
              <TestimonailCard key={index}>
                <div dir="ltr">
                  {(item.avatar) ? 
                    <div className="avatar">
                      {/* <img src={item.avatar} alt={`Avatar of ${(item.name) ? item.name : 'author'}`} /> */}
                      <Image filename={item.avatar} />
                    </div> : null}
                  {(item.name) ? <h3 className="name">{item.name}</h3> : null}
                  {(item.author) ? <a href={item.author.url} className="link">{item.author.label}</a> : null}
                  {(item.text) ? <div dangerouslySetInnerHTML={{__html: item.text}} className="text"></div> : null}


                  <div className="footer">
                    
                    {(item.footer.link && item.footer.link.url) ? (
                      <a href={item.footer.link.url} className="company" target="_blank" rel="noopener noreferrer">
                        {(item.footer.avatar) ? (
                          <span className="avatar">
                            {/* <img src={item.footer.avatar} alt={item.footer.title} /> */}
                            <Image filename={item.footer.avatar} />
                          </span>
                          ) : null}
                        <span className="name">
                          {(item.footer.title) ? (<span className="title" dangerouslySetInnerHTML={{__html: item.footer.title}} ></span>) : null}
                          {(item.footer.link.label) ? (<span className="label">{item.footer.link.label}</span>) : null}
                        </span>
                      </a>
                    ) : null}

                    {(item.tweet.url) ? (
                      <a href={item.tweet.url} className="tweet" target="_blank" rel="noopener noreferrer">
                        <span className="accessibility-hidden">See the detail of the {item.name} tweet</span>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.7402 2.46993C12.6535 2.38327 12.5668 2.38327 12.4802 2.4266C12.3502 2.46993 12.1768 2.5566 12.0035 2.64327C12.1768 2.38327 12.3068 2.07993 12.3502 1.81993C12.3935 1.73327 12.3502 1.6466 12.2635 1.60327C12.1768 1.55993 12.0902 1.55993 12.0035 1.60327C11.5702 1.86327 10.9635 2.07993 10.7468 2.12327C10.2702 1.73327 9.6635 1.5166 9.10016 1.5166C8.3635 1.5166 7.67016 1.81993 7.10683 2.33993C6.58683 2.85993 6.2835 3.55327 6.2835 4.33327C4.16016 4.1166 2.9035 2.8166 1.90683 1.60327C1.8635 1.55993 1.77683 1.5166 1.7335 1.5166C1.64683 1.5166 1.6035 1.55993 1.56016 1.60327C0.953497 2.59993 1.0835 3.5966 1.2135 4.07327C1.30016 4.41993 1.43016 4.67993 1.6035 4.93993C1.38683 4.85327 1.2135 4.7666 1.04016 4.59327C0.996831 4.54994 0.866831 4.54993 0.823497 4.54993C0.736831 4.59327 0.693497 4.67993 0.693497 4.7666C0.693497 6.02327 1.56016 6.8466 2.2535 7.27993C2.1235 7.2366 1.9935 7.2366 1.82016 7.14993C1.7335 7.1066 1.64683 7.14994 1.6035 7.19327C1.56016 7.2366 1.51683 7.3666 1.56016 7.40993C1.95016 8.31993 2.81683 9.01327 3.77016 9.1866C2.9035 9.7066 1.51683 10.0099 0.476831 9.92327C0.390164 9.92327 0.260164 9.9666 0.260164 10.0533C0.216831 10.1399 0.260164 10.2699 0.346831 10.3133C1.64683 11.0499 3.0335 11.4399 4.37683 11.4399C6.50016 11.4399 8.6235 10.4866 10.0968 8.7966C11.3968 7.3666 12.0902 5.5466 11.9602 3.94327C12.1768 3.76994 12.3068 3.4666 12.4802 3.16327C12.5668 3.03327 12.6535 2.85994 12.7402 2.72993C12.7835 2.64327 12.7835 2.51327 12.7402 2.46993Z"/>
                        </svg>
                      </a>
                    ) : null}


                  </div>
                </div>
              </TestimonailCard>
            ))}
          </Swiper>
        </div>
      </div>
      <div className="slider-buttons">
        <button className="button-slider" onClick={goNext} data-sal="fade" data-sal-duration="500">
          <span className="accessibility-hidden">Next</span>
          <svg width="22" height="8" viewBox="0 0 22 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.935547 4.40381C0.659405 4.40381 0.435547 4.17995 0.435547 3.90381C0.435547 3.62767 0.659404 3.40381 0.935547 3.40381L0.935547 4.40381ZM21.4181 3.55025C21.6134 3.74552 21.6134 4.0621 21.4181 4.25736L18.2362 7.43934C18.0409 7.6346 17.7243 7.6346 17.529 7.43934C17.3338 7.24408 17.3338 6.9275 17.529 6.73223L20.3575 3.90381L17.529 1.07538C17.3338 0.880118 17.3338 0.563536 17.529 0.368273C17.7243 0.173011 18.0409 0.173011 18.2362 0.368273L21.4181 3.55025ZM0.935547 3.40381L21.0646 3.40381L21.0646 4.40381L0.935547 4.40381L0.935547 3.40381Z" />
          </svg>
        </button>
        <button className="button-slider" onClick={goPrev} data-sal="fade" data-sal-duration="500">
          <span className="accessibility-hidden">Previous</span>
          <svg width="22" height="9" viewBox="0 0 22 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.0645 3.85596C21.3406 3.85596 21.5645 4.07981 21.5645 4.35596C21.5645 4.6321 21.3406 4.85596 21.0645 4.85596L21.0645 3.85596ZM0.581869 4.70951C0.386608 4.51425 0.386608 4.19767 0.581869 4.00241L3.76385 0.820426C3.95911 0.625164 4.27569 0.625164 4.47096 0.820426C4.66622 1.01569 4.66622 1.33227 4.47096 1.52753L1.64253 4.35596L4.47096 7.18439C4.66622 7.37965 4.66622 7.69623 4.47096 7.89149C4.27569 8.08676 3.95911 8.08676 3.76385 7.89149L0.581869 4.70951ZM21.0645 4.85596L0.935423 4.85596L0.935423 3.85596L21.0645 3.85596L21.0645 4.85596Z"/>
          </svg>
        </button>
      </div>
    </Container>
  );
}

export default Testimonials;