import styled from 'styled-components';
import variables, { mq, fs } from '../../utils/variables';

export const Container = styled.div`
  display: flex;
  justify-content: ${props => props.alignOn === 'left' ? 'flex-start' : 'center'};
  align-items: center;
  margin: 0 auto;
  max-width: 65vw;
  /* min-height: calc(80vh -  180px); */
  padding: 7vw 4vw 0;

  ${mq('l')} {
    padding: 1.45rem 35px;
    max-width: none;
  }

  ${mq('s')} {
    padding: 1.45rem 20px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 0 35px; */
  width: 100%;

  ${mq('m')} {
    flex-direction: column;
    align-items: flex-start;
  }

  .content {
    width: 50%;

    ${mq('m')} {
      width: calc(100vw - 70px);
    }

    ${mq('s')} {
      width: calc(100vw - 40px);
    }
  }

  .cloud {
    position: relative;
    width: 50%;
    height: 34vw;

    ${mq('m')} {
      width: 100%;
      height: 100vw;
      margin: 0 auto;
    }
  }

  .text {
    padding-right: 5vw;
    color: ${ props => props.dark ? variables.colors.white : variables.colors.backgroundDark};

    ${mq('m')} {
      padding-right: 0;
      max-width: 475px;
    }

    p {
      color: ${ props => props.dark ? variables.colors.white : variables.colors.backgroundDark};
      font-family: 'Bossa', sans-serif;
      font-weight: 500;

      ${fs(34)}
    }

    p + p {
      margin-top: 10px;
    }
  }
`;

export const Bubble = styled.div`
  position: absolute;
  top: ${props => props.top}vw;
  left: ${props => props.left}vw;
  transform: scale(${props => props.scale});

  ${mq('m')} {
    top: ${props => props.mTop}vw;
    left: ${props => props.mLeft}vw;
    transform: scale(${props => props.mScale});
  }

  a,
  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 11.5vw;
    height: 11.5vw;
    border-radius: 50%;
    overflow: hidden;
    margin: 0;
    background: ${variables.colors.backgroundDark};
    box-shadow: 0px 0.5vw 5vw rgba(7, 13, 28, 0.25);

    ${mq('m')} {
      width: 36vw;
      height: 36vw;
    }
    
    img {
      display: block;
      width: 65%;
      height: 40%;
      margin: 0;
      opacity: .5;
      object-fit: contain;
    }
  }
`;