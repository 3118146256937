import PropTypes from 'prop-types';
import React from 'react';

import { Container, Content, Bubble } from './styled.js';

import dogstudio from '../../images/partners/new/dogstudio.svg';
import base from '../../images/partners/new/base.svg';
import bunch from '../../images/partners/new/bunch.svg';
import fresh from '../../images/partners/new/fresh.svg';
import paradowski from '../../images/partners/new/paradowski.svg';
import sens from '../../images/partners/new/sens.svg';
import zdf_digital from '../../images/partners/new/zdf_digital.svg';

const partnersList = [
  {
    name: 'ZDF digital',
    logo: zdf_digital,
    top: 21,
    left: 37,
    scale: 0.43,
    mTop: 10.93,
    mLeft: 55.10,
    mScale: 0.43,
  },
  {
    name: 'Base',
    logo: base,
    top: 11.98,
    left: 29.69,
    scale: 0.61,
    mTop: 39.57,
    mLeft: 60.53,
    mScale: 0.61,
  },
  {
    name: 'Paradowski',
    logo: paradowski,
    top: 12.06,
    left: 15.63,
    scale: 0.43,
    mTop: 41.33,
    mLeft: 30.33,
    mScale: 0.43,
  },
  {
    name: 'Sens',
    logo: sens,
    top: 24.74,
    left: 8.75,
    scale: 0.54,
    mTop: 67.73,
    mLeft: 10.33,
    mScale: 0.54,
  },
  {
    name: 'Fresh',
    logo: fresh,
    top: 0,
    left: 8.07,
    scale: 1,
    mTop: 0,
    mLeft: 18.20,
    mScale: 1,
  },
  {
    name: 'Bunch',
    logo: bunch,
    top: 26.27,
    left: 21.61,
    scale: 1,
    mTop: 77.87,
    mLeft: 44.87,
    mScale: 1,
  },
  {
    name: 'Dogstudio',
    logo: dogstudio,
    top: 13.54,
    left: 0,
    scale: 0.7,
    mTop: 40.27,
    mLeft: -5,
    mScale: 0.7,
  }
];

const Partners = (props) => {
  return (
    <Container {...props}>
      <Content {...props}>
        <div className="content" data-sal="slide-right" data-sal-duration="500">
          <div className="text">
            <p>The coolest companies already use Ludus.</p>
          </div>
        </div>
        <div className="cloud">
          {partnersList.map((item, index) => (
            <Bubble
              key={index}
              top={item.top}
              left={item.left}
              scale={item.scale}
              mTop={item.mTop}
              mLeft={item.mLeft}
              mScale={item.mScale}
            >
              {(item.url) ? (
                <a href={item.url} className="cloud-bubble">
                  <img src={item.logo} alt={`Logo of ${item.name}`} width="65%" height="40%" />
                </a>
              ) : (
                <span 
                  className="cloud-bubble"
                  data-sal="fade"
                  data-sal-delay="500"
                  data-sal-duration="500"
                >
                  <img src={item.logo} alt={`Logo of ${item.name}`} width="65%" height="40%" />
                </span>
              )}
            </Bubble>
          ))}
        </div>
      </Content>
    </Container>
  )
}

Partners.propTypes = {
  dark: PropTypes.bool
}

Partners.defaultProps = {
  siteTitle: ``,
}

export default Partners;
