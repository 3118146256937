import styled from 'styled-components';
import variables, { screenSize, isTouchMedia } from '../../utils/variables';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  background: rgba(0, 0, 0, .8);

  opacity: ${props => props.opened ? '1' : '0'};
  pointer-events: ${props => props.opened ? 'all' : 'none'};

  .close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 20;
    border: 0;
    background: ${variables.colors.blue} none;
    transition: all .3s ease-in;
    outline: none;
    cursor: pointer;

    ${screenSize('width', 50)}
    ${screenSize('height', 50)}
    border-radius: 100%;


    svg {
      max-width: 60%;
      max-height: 60%;
      path {
        fill: ${variables.colors.white};
        transition: all .2s;

        &:nth-child(1) {
          transform: rotate(45deg) translate(calc(100% + 4px),-6px) scaleX(-1);
        }

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(-12px,-2px);
        }
      }
    }

    ${isTouchMedia()} {
      &:hover {

        background-color: ${variables.colors.red};

        svg {
          path {
            fill: ${variables.colors.white};
          }
        }
      }
    }

  
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  video {
    position: relative;
    max-height: 90%;
    max-width: 90%;
    outline: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

`;