import PropTypes from 'prop-types';
import React from 'react';

import { Container, Content } from './styled.js';

const Hero = (props) => {

  if(props.opened === true) {
    props.handleOpen();
  } else if(props.opened === false) {
    props.handleClose();
  }


  return (
    <Container {...props}>
      <button 
        className="close"
        onClick={() => {
          props.closeOverlayerFn(false);
        }}
      >
        <span className="accessibility-hidden">Close</span>
        <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0H28.7179L32 4H4.10256L0 0Z"/>
          <path d="M0 10H28.7179L32 14H4.10256L0 10Z"/>
          <path d="M0 20H28.7179L32 24H4.10256L0 20Z"/>
        </svg>
      </button>
      <Content {...props}>
        <div 
          className="overlay"
          onClick={() => {
            props.closeOverlayerFn(false);
          }}
        />
        {props.children}
      </Content>
    </Container>
  )
}

Hero.propTypes = {
  dark: PropTypes.bool
}

Hero.defaultProps = {
  siteTitle: ``,
}

export default Hero;
